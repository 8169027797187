html, body {
    box-sizing: border-box;
    height: 100%;
    padding: 0;
    margin: 0;
}
.wrapper {
    box-sizing: border-box;
    min-height: 100%;
    display: flex;
    flex-direction: column;
}
.content {
    flex-grow: 1;
    margin: 0px, 30px, 30px, 30px;
}
.page-header, .page-footer {
    flex-grow: 0;
    flex-shrink: 0;
}
.page-footer {
    padding: 30px;
}

#MerchantContainer {
    height: 100%;
}

.loading::after {
    display: inline-block;
    animation: dotty steps(1) 2s infinite;
    content: '';
  }
  
  @keyframes dotty {
      0%   { content: ''; }
      25%  { content: '.'; }
      50%  { content: '..'; }
      75%  { content: '...'; }
      100% { content: ''; }
  }

  /* section {
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    scroll-snap-align: center;
    perspective: 500px;
  }
  
  section > div {
    width: 1px;
    position: relative;
    max-height: 90vh;
    overflow: visible;
  } */

  .gradient {
    background: linear-gradient(to right, #ff8a00 0%, #dd4c4f 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .hero-image {
    position: relative;
    width: 100%;
    height: 100vh;
    /* background-image: url("https://images.pexels.com/photos/9052284/pexels-photo-9052284.jpeg?auto=compress&cs=tinysrgb&w=3000&h=2000&dpr=1&q=80"); */
    background-size: cover;
    background-position: center;
  }
  
  .fade-effect::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7); /* Adjust the alpha value to control opacity */
  }
