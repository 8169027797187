#video-container {
  position: absolute;
  z-index: 1500;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

#video-container video {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}