html, body {
    box-sizing: border-box;
    height: 100%;
    padding: 0;
    margin: 0;
}

.stampCup {
    /* mix-blend-mode:color-burn */
}

.stampMask { 
    mask-type:alpha
}

.stampBG {
    mix-blend-mode: darken;
	mix-blend-mode: difference;
	mix-blend-mode: exclusion;
	mix-blend-mode: multiply;
}